import React, { useState, useRef, useCallback } from 'react'
import {
  IonContent,
  IonPage,
  IonFab,
  IonFabButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
} from '@ionic/react'
import { camera } from 'ionicons/icons'
import { useHistory } from 'react-router'

import './Face.css'

import { usePhotoGallery, usePortraitList } from '../hooks'
import { PortraitImage } from './PortraitImage'
import Menu from '../components/Menu'

const Face = (props: any) => {
  const { setCameraActive, present } = usePhotoGallery()
  const [pageNumber, setPageNumber] = useState(1)
  const { portraits, hasMore, loading, error } = usePortraitList(pageNumber)
  const history = useHistory()

  const observer: any = useRef()

  const lastPortraitElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore],
  )

  return (
    <IonPage>
      <Menu contentId='faceContent' {...props} />
      <IonContent id='faceContent' scrollEvents={true}>
        <IonGrid>
          <IonRow>
            <IonCol size='12'>EXPLORE</IonCol>
          </IonRow>
          <IonRow>
            {portraits.map((portrait: any, i) => {
              let test = null
              if (portraits.length === i + 1) {
                test = <div ref={lastPortraitElementRef} />
              }
              return (
                <IonCol
                  style={{ minHeight: '300px' }}
                  sizeXs='6'
                  sizeSm='6'
                  sizeMd='3'
                  key={i}
                >
                  {test}
                  <PortraitImage portrait={portrait} {...props} />
                </IonCol>
              )
            })}
            {loading && (
              <IonCol
                style={{ minHeight: '300px' }}
                sizeSm='6'
                sizeMd='3'
                sizeXs='6'
              >
                <div className='loader'>
                  <IonSpinner name='dots' />
                </div>
              </IonCol>
            )}
            <div>{error && 'Error'}</div>
          </IonRow>
        </IonGrid>
        <IonFab vertical='bottom' horizontal='center' slot='fixed'>
          <IonFabButton
            onClick={(e) => {
              e.preventDefault()
              setCameraActive(true)
              present({
                message: 'Loading...',
              })
              history.push('/capture')
            }}
          >
            <IonIcon icon={camera}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  )
}

export default Face
