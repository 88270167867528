import React, { useState, useRef, useCallback } from 'react'
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react'

import { usePortraitList } from '../hooks'
import { PortraitImage } from './PortraitImage'
import Menu from '../components/Menu'

const PersonalPortraits = (props: any) => {
  const [pageNumber, setPageNumber] = useState(1)
  const { portraits, hasMore, loading, error } = usePortraitList(
    pageNumber,
    localStorage.getItem('user_id'),
  )

  const observer: any = useRef()

  const lastPortraitElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore],
  )

  return (
    <IonPage>
      <Menu contentId='personalContent' {...props} />
      <IonContent id='personalContent' scrollEvents={true}>
        <IonGrid>
          <IonRow>
            {portraits.map((portrait: any, i) => {
              if (portraits.length === i + 1) {
                return (
                  <IonCol size='3' key={i}>
                    <div ref={lastPortraitElementRef}>
                      <PortraitImage portrait={portrait} {...props} />
                    </div>
                  </IonCol>
                )
              }
              return (
                <IonCol size='3' key={i}>
                  <PortraitImage portrait={portrait} {...props} />
                </IonCol>
              )
            })}
            <div>{loading && 'Loading...'}</div>
            <div>{error && 'Error'}</div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default PersonalPortraits
