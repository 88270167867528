import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import * as faceapi from 'face-api.js'

import Capture from './pages/Capture'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

/* Global CSS */
import './global.css'

import { PhotoProvider } from './provider/PhotoProvider'
import { UserProvider } from './provider/UserProvider'
import { ProtectedRoute } from './components/ProtectedRoute'
import { UnprotectedRoute } from './components/UnprotectedRoute'

import {
  Face,
  PhotoResult,
  Start,
  Login,
  Register,
  PersonalPortraits,
} from './pages'
import Preview from './pages/Preview'
import Save from './pages/Save'

const App = () => {
  useEffect(() => {
    faceapi.loadSsdMobilenetv1Model('/weights')
  }, [])
  return (
    <UserProvider>
      <PhotoProvider>
        <IonApp>
          <IonReactRouter>
            <IonRouterOutlet>
              <UnprotectedRoute path='/start' component={Start} exact={true} />
              <UnprotectedRoute path='/login' component={Login} exact={true} />
              <UnprotectedRoute
                path='/register'
                component={Register}
                exact={true}
              />
              <ProtectedRoute path='/face' component={Face} />
              <ProtectedRoute path='/capture' component={Capture} />
              <ProtectedRoute path='/preview' component={Preview} />
              <ProtectedRoute path='/save' component={Save} />
              <ProtectedRoute path='/result' component={PhotoResult} />
              <ProtectedRoute path='/personal' component={PersonalPortraits} />
              <Route
                path='/'
                render={() => <Redirect to='/start' />}
                exact={true}
              />
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      </PhotoProvider>
    </UserProvider>
  )
}
export default App
