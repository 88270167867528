import React, { useEffect } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonButton,
  IonButtons,
  IonImg,
} from '@ionic/react'
import { closeOutline, grid } from 'ionicons/icons'
import { useHistory } from 'react-router'

import { usePhotoGallery } from '../hooks'

const Preview = (props: any) => {
  const history = useHistory()
  const { startCamera, stopCamera, setCameraActive, portraitCanvas } =
    usePhotoGallery()

  useEffect(() => {
    if (!portraitCanvas) {
      history.push('face')
    }
  }, [portraitCanvas, history])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              onClick={() => {
                stopCamera().then(() => {
                  setCameraActive(false)
                  history.push('/face')
                })
              }}
            >
              <IonIcon slot='icon-only' icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot='primary'>
            <IonButton>
              <IonIcon slot='icon-only' icon={grid} />
            </IonButton>
          </IonButtons>
          <IonTitle>Capture</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonImg
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          src={portraitCanvas}
          alt=''
        />
        <div>
          <IonButton
            onClick={() => {
              history.push('/save')
            }}
          >
            Save
          </IonButton>
          <IonButton
            onClick={() => {
              setCameraActive(true)
              history.push('/capture')
              startCamera()
            }}
          >
            Repeat
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Preview
