import React, { useState, useEffect } from 'react'
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
} from '@ionic/react'
import { useHistory } from 'react-router'

import { useAuth } from '../hooks'

const Register = (props: any) => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { register } = useAuth()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) history.push('/face')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position='stacked'>Email</IonLabel>
                <IonInput
                  onIonChange={(e) => setEmail(e.detail.value!)}
                  value={email}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position='stacked'>Password</IonLabel>
                <IonInput
                  type='password'
                  onIonChange={(e) => setPassword(e.detail.value!)}
                  value={password}
                ></IonInput>
              </IonItem>
              <IonButton
                onClick={async () => {
                  register(email, password)
                }}
              >
                Get Started
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Register
