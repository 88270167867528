import React, { useRef, useEffect, useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButton,
  IonButtons,
  useIonAlert,
} from '@ionic/react'
import { camera, closeOutline, grid } from 'ionicons/icons'
import { useHistory } from 'react-router'

import { usePhotoGallery } from '../hooks'

import './Capture.css'

const Capture = (props: any) => {
  const history = useHistory()
  const [shutter, setShutter] = useState(false)
  const [faceError] = useIonAlert()
  const {
    startCamera,
    capturePhoto,
    stopCamera,
    cameraActive,
    setCameraActive,
    dismiss,
  } = usePhotoGallery()
  const imageCanvas = useRef(null)

  useEffect(() => {
    if (!cameraActive) {
      history.push('/face')
    }

    startCamera().then(() => {
      console.log('dismissed')
      dismiss()
    })
    console.log('hola')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraActive])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              onClick={() => {
                stopCamera().then(() => {
                  setCameraActive(false)
                  history.push('/face')
                })
              }}
            >
              <IonIcon slot='icon-only' icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot='primary'>
            <IonButton>
              <IonIcon slot='icon-only' icon={grid} />
            </IonButton>
          </IonButtons>
          <IonTitle>Capture</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {shutter && (
          <div className='photoShutter'>
            <div>
              <div>1,2,3,4,5...</div>
              <div>Act the emotion and listen to how you feel.</div>
            </div>
          </div>
        )}
        <div className='cameraWrapper'>
          <div className='divCameraPreviewContent'>
            <div className='faceMask'>
              <img src='assets/mask.png' alt='mask'></img>
              <div
                className='content-camera-preview'
                id='divCameraPreviewContent'
              ></div>
            </div>
            <canvas className='workingCanvas' ref={imageCanvas} />
          </div>
        </div>
        <IonFab vertical='bottom' horizontal='center' slot='fixed'>
          <IonFabButton
            onClick={() => {
              setShutter(true)
              capturePhoto()
                .then(() => {
                  history.push('/preview')
                })
                .catch(() => {
                  faceError({
                    cssClass: 'my-css',
                    header: 'No se ha encontrado una cara',
                    message: 'Prueba otra vez',
                    buttons: ['Close'],
                  })
                })
              setShutter(false)
            }}
          >
            <IonIcon icon={camera}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  )
}

export default Capture
