import React, { useState } from 'react'
import { PhotoContext } from '../context/PhotoContext'

export const PhotoProvider = ({ children }: { children: React.ReactNode }) => {
  const [cameraActive, setCameraActive] = useState<boolean>(false)
  const [portraitId, setPortraitId] = useState<string>('')
  const [portraitParts, setPortraitParts] = useState<Array<string>>([])
  const [portraitCanvas, setPortraitCanvas] = useState<string>('')

  return (
    <PhotoContext.Provider
      value={{
        portraitId,
        setPortraitId,
        cameraActive,
        setCameraActive,
        portraitParts,
        setPortraitParts,
        portraitCanvas,
        setPortraitCanvas,
      }}
    >
      {children}
    </PhotoContext.Provider>
  )
}
