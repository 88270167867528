import { createContext, Dispatch, SetStateAction } from 'react'

export interface Photo {
  cameraActive: boolean
  portraitId: string
  portraitCanvas: string
  portraitParts: Array<string>
  setCameraActive: Dispatch<SetStateAction<boolean>>
  setPortraitId: Dispatch<SetStateAction<string>>
  setPortraitCanvas: Dispatch<SetStateAction<string>>
  setPortraitParts: Dispatch<SetStateAction<string[]>>
}

export const PhotoContext = createContext<Photo>({
  cameraActive: false,
  portraitId: '',
  portraitParts: [],
  portraitCanvas: '',
  setPortraitId: () => {},
  setCameraActive: () => {},
  setPortraitCanvas: () => {},
  setPortraitParts: () => {},
})
