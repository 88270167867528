import { useEffect, useContext, useState } from 'react'
import * as faceapi from 'face-api.js'
import {
  CameraPreview,
  CameraPreviewOptions,
  CameraPreviewPictureOptions,
} from '@capacitor-community/camera-preview'
import axios from 'axios'
import { PhotoContext } from '../context/PhotoContext'
import { getToken, getUserId } from '../utils'
import { useIonLoading } from '@ionic/react'

function getFaceDetectorOptions(net: faceapi.NeuralNetwork<any>) {
  // SsdMobilenetv1Options
  const minConfidence = 0.5

  // TinyFaceDetectorOptions
  const inputSize = 408
  const scoreThreshold = 0.5
  return net === faceapi.nets.ssdMobilenetv1
    ? new faceapi.SsdMobilenetv1Options({ minConfidence })
    : new faceapi.TinyFaceDetectorOptions({ inputSize, scoreThreshold })
}

async function setPortraitImage(portrait: any) {
  const bearer: any = await getToken()
  return await axios
    .get(portrait, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
    .then((res) => {
      return Buffer.from(res.data).toString('base64')
    })
}

function getFormData(object: any) {
  const formData = new FormData()
  Object.keys(object).forEach((key) => formData.append(key, object[key]))
  return formData
}

export default function usePhotoGallery() {
  const [mainPortrait, setMainPortrait] = useState('')
  const [leftPortrait, setLeftPortrait] = useState('')
  const [rightPortrait, setRightPortrait] = useState('')
  const [present, dismiss] = useIonLoading()

  const ctxt = useContext(PhotoContext)

  useEffect(() => {}, [])

  const faceDetectionNet = faceapi.nets.ssdMobilenetv1
  const faceDetectionOptions = getFaceDetectorOptions(faceDetectionNet)

  const urltoFile = (url: any, filename: any, mimeType: any) => {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1]
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer()
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType })
      })
  }

  const storePortrait = async (canvas: any, name: string, privacy: boolean) => {
    const bearer: any = getToken()
    const author_id: any = getUserId()
    const main_portrait = await urltoFile(canvas, 'mainPortrait.jpg', 'jpg')
    const left_portrait = await urltoFile(
      ctxt.portraitParts[0],
      'leftPortrait.jpg',
      'jpg',
    )
    const right_portrait = await urltoFile(
      ctxt.portraitParts[1],
      'rightPortrait.jpg',
      'jpg',
    )

    const data = {
      main_portrait,
      right_portrait,
      left_portrait,
      author_id,
      name,
      privacy: privacy ? 'public' : 'private',
    }

    const formData = getFormData(data)

    return await axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/portrait`, formData, {
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      })
      .then(() => {
        console.log('todo bien')
      })
      .catch(() => console.log('error'))
  }

  const capturePhoto = async () => {
    const cameraPreviewPictureOptions: CameraPreviewPictureOptions = {
      quality: 50,
    }

    const result = await CameraPreview.capture(cameraPreviewPictureOptions)

    const image: HTMLImageElement = new Image()
    image.src = `data:image/png;base64, ${result.value}`

    const detections = (await faceapi.detectSingleFace(
      image,
      faceDetectionOptions,
    )) as faceapi.FaceDetection

    if (!detections) return Promise.reject()

    const { width, height, x, y } = detections.box

    const regionsToExtract = [
      new faceapi.Rect(x - width / 2, y - height / 2, width * 2, height * 2),
    ]

    const faceImages = await faceapi.extractFaces(image, regionsToExtract)

    const testCanvas = document.createElement('canvas')
    const parts: Array<string> = []
    const ctx = testCanvas.getContext('2d')

    let img = new Image()
    img.onload = split_2
    img.src = faceImages[0].toDataURL()

    function split_2() {
      const w2 = img.width / 2
      const h2 = img.height

      for (let i = 0; i < 2; i++) {
        const x = (-w2 * i) % (w2 * 2)
        const y = h2 * i <= h2 ? 0 : -h2

        testCanvas.width = w2
        testCanvas.height = h2

        ctx!.drawImage(img, x, y, w2 * 2, h2)

        parts.push(testCanvas.toDataURL())
        ctxt.setPortraitParts(parts)
      }
    }

    ctxt.setPortraitCanvas(faceImages[0].toDataURL())

    return await Promise.resolve()
  }

  const getPortrait = async (id: string) => {
    const bearer: any = await getToken()

    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/portrait/${id}`, {
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  const getPortraitImages = async (portrait: any) => {
    const main = await setPortraitImage(portrait.main_portrait)
    const right = await setPortraitImage(portrait.right_portrait)
    const left = await setPortraitImage(portrait.left_portrait)

    setMainPortrait(`data:image/png;base64,${main}`)
    setRightPortrait(`data:image/png;base64,${right}`)
    setLeftPortrait(`data:image/png;base64,${left}`)
  }

  const startCamera = () => {
    const cameraPreviewOptions: CameraPreviewOptions = {
      disableAudio: true,
      className: 'videoTest',
      parent: 'divCameraPreviewContent',
      height: 1920,
      width: 1080,
    }

    CameraPreview.start(cameraPreviewOptions).catch(() =>
      console.log('erroooor'),
    )

    return Promise.resolve()
  }

  const stopCamera = () => {
    return CameraPreview.stop()
  }

  return {
    cameraActive: ctxt.cameraActive,
    setCameraActive: ctxt.setCameraActive,
    portraitId: ctxt.portraitId,
    setPortraitId: ctxt.setPortraitId,
    portraitParts: ctxt.portraitParts,
    portraitCanvas: ctxt.portraitCanvas,
    storePortrait,
    getPortraitImages,
    capturePhoto,
    getPortrait,
    startCamera,
    stopCamera,
    mainPortrait,
    leftPortrait,
    rightPortrait,
    present,
    dismiss,
  }
}
