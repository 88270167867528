import React, { useState } from 'react'
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
} from '@ionic/react'

import { useAuth } from '../hooks'

const Login = (props: any) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login } = useAuth()

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position='stacked'>Email</IonLabel>
                <IonInput
                  type='email'
                  onIonChange={(e) => setEmail(e.detail.value!)}
                  value={email}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position='stacked'>Password</IonLabel>
                <IonInput
                  type='password'
                  onIonChange={(e) => setPassword(e.detail.value!)}
                  value={password}
                ></IonInput>
              </IonItem>
              <IonButton
                onClick={() => {
                  login(email, password)
                }}
              >
                Get Started
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Login
