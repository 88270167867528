import { useContext } from 'react'
import axios from 'axios'

import { UserContext } from '../context/UserContext'
import { useHistory } from 'react-router'

export default function useAuth() {
  const ctxt = useContext(UserContext)
  const history = useHistory()

  const setUserData = (data: any) => {
    ctxt.setBearerToken(data.access_token)
    localStorage.setItem('token', data.access_token)
    localStorage.setItem('user_id', data.user.id)
    history.push('/face')
    return data
  }

  const login = (email: string, password: string) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/login`, {
        email,
        password,
      })
      .then(({ data }: any) => {
        setUserData(data)
      })
      .catch((e) => {
        return Promise.reject()
      })
  }

  const register = (email: string, password: string) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/register`, {
        email,
        password,
      })
      .then(({ data }: any) => {
        setUserData(data)
      })
      .catch((e) => {
        return Promise.reject()
      })
  }

  const logout = () => {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/logout`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(() => {
        localStorage.removeItem('token')
        window.location.reload()
      })
      .catch((e) => console.log(e))
  }

  return {
    login,
    register,
    logout,
    bearer: ctxt.bearer,
  }
}
