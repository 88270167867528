import React, { useEffect, useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonButton,
  IonButtons,
  IonLabel,
  IonInput,
  IonItem,
  IonImg,
  IonCheckbox,
} from '@ionic/react'
import { closeOutline, grid } from 'ionicons/icons'
import { useHistory } from 'react-router'

import { usePhotoGallery } from '../hooks'

const Save = (props: any) => {
  const history = useHistory()
  const { stopCamera, setCameraActive, portraitCanvas, storePortrait } =
    usePhotoGallery()
  const [name, setName] = useState('')
  const [privacy, setPrivacy] = useState(false)

  useEffect(() => {
    if (!portraitCanvas) {
      history.push('face')
    }
  }, [portraitCanvas, history])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              onClick={() => {
                stopCamera().then(() => {
                  setCameraActive(false)
                  history.push('/face')
                })
              }}
            >
              <IonIcon slot='icon-only' icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot='primary'>
            <IonButton>
              <IonIcon slot='icon-only' icon={grid} />
            </IonButton>
          </IonButtons>
          <IonTitle>Capture</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonImg
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          src={portraitCanvas}
          alt=''
        />
        <div>
          <IonItem>
            <IonLabel position='fixed'>Name</IonLabel>
            <IonInput
              type='text'
              onIonChange={(e) => setName(e.detail.value!)}
              value={name}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Privacy</IonLabel>
            <IonCheckbox
              checked={privacy}
              onIonChange={(e) => setPrivacy(e.detail.checked)}
            />
          </IonItem>
          <IonButton
            onClick={() => {
              storePortrait(portraitCanvas, name, privacy).then(() =>
                history.push('personal'),
              )
            }}
          >
            Save
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Save
