import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router'

import { usePhotoGallery } from '../../hooks'
import Input from './Input'
import NavigationButtons from './NavigationButtons'

export const getToken = () => {
  return localStorage.getItem('token')
}

export const getPortraitId = () => {
  return localStorage.getItem('portraitId')
}

export const getUserId = () => {
  return localStorage.getItem('user_id')
}

const Questions = (props: any) => {
  const history = useHistory()
  const [nextQuestion, setNextQuestion] = useState(1)
  const [previousQuestion, setPreviousQuestion] = useState([] as Array<any>)
  const [currentQuestion, setCurrentQuestion] = useState(1)
  const [questions, setQuestions] = useState([])
  const [answer, setAnswer] = useState('')
  const { portraitId } = usePhotoGallery()

  const {
    getPortrait,
    getPortraitImages,
    mainPortrait,
    leftPortrait,
    rightPortrait,
  } = usePhotoGallery()

  const loadPortraitQuestions = async () => {
    const bearer: any = getToken()
    if (!portraitId) {
      history.push('/')
      return
    }

    const questions: any = await axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/forms?locale=en`, {
        headers: { Authorization: `Bearer ${bearer}` },
      })
      .then((res: any) => (res.data[0] ? res.data[0].form : []))
    setQuestions(questions)
    const selectedPortrait: any = await getPortrait(portraitId)
    getPortraitImages(selectedPortrait)
  }

  useEffect(() => {
    loadPortraitQuestions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setPortrait = (portraitType: string) => {
    if (!portraitType) return

    switch (portraitType) {
      case 'main':
        return mainPortrait
      case 'left':
        return leftPortrait
      case 'right':
        return rightPortrait
      default:
        return
    }
  }

  if (!questions) return null

  const selectedQuestion: any = questions.find(
    (question: any) => question.id === nextQuestion,
  )

  if (!selectedQuestion) return null

  const { text, type, options, portrait } = selectedQuestion

  return (
    <div>
      <img src={setPortrait(portrait)} alt='portrait' />
      <Input
        text={text}
        type={type}
        options={options}
        setAnswer={setAnswer}
        setCurrentQuestion={setCurrentQuestion}
      />
      <NavigationButtons
        questions={questions}
        setQuestions={setQuestions}
        selectedQuestion={selectedQuestion}
        previousQuestion={previousQuestion}
        setPreviousQuestion={setPreviousQuestion}
        setNextQuestion={setNextQuestion}
        currentQuestion={currentQuestion}
        answer={answer}
      />
    </div>
  )
}

export default Questions
