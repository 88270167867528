import { IonImg } from '@ionic/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import './PortraitImage.css'
import { usePhotoGallery } from '../hooks'

export const PortraitImage = (props: any) => {
  const { portrait } = props
  const history = useHistory()
  const [image, setImage] = useState('')
  const { setPortraitId } = usePhotoGallery()

  function getImage(portrait: any) {
    return axios
      .get(
        portrait.main_portrait.replace(
          'http://localhost:8000',
          process.env.REACT_APP_API_ENDPOINT,
        ),
        {
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        return `data:image/png;base64,${Buffer.from(res.data).toString(
          'base64',
        )}`
      })
  }

  useEffect(() => {
    const setUserImage = async () => {
      const img = await getImage(portrait)
      setImage(img)
    }
    setUserImage()
  }, [portrait])

  return (
    <IonImg
      className='portraitImage'
      src={image}
      onClick={() => {
        setPortraitId(portrait.id)
        history.push('/result')
      }}
      alt=''
    />
  )
}
