import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export const ProtectedRoute = (props: any) => {
  const { component: Component, path } = props
  const isAuth = localStorage.getItem('token')

  return (
    <Route
      path={path}
      render={(routeProps) => {
        return isAuth ? (
          <Component {...props} {...routeProps} />
        ) : (
          <Redirect to='/start' />
        )
      }}
    />
  )
}
