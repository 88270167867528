import React from 'react'
import Select from 'react-select'

const Input = (props: any) => {
  const { text, type, options, setAnswer, setCurrentQuestion } = props

  let questionInput = <input onChange={(e) => setAnswer(e.target.value)} />

  if (type === 'select') {
    questionInput = (
      <Select
        name='options'
        options={options}
        getOptionLabel={(option: any) => option.name}
        getOptionValue={(option: any) => option.name}
        isClearable
        classNamePrefix='select'
        onChange={(e) => {
          setCurrentQuestion(e.next ? e.next : 0)
          setAnswer(e.name)
        }}
      />
    )
  }
  return (
    <div>
      <div>{text}</div>
      {questionInput}
    </div>
  )
}

export default Input
