import React from 'react'
import axios from 'axios'
import { useHistory } from 'react-router'
import { usePhotoGallery } from '../../hooks'
import { getToken, getUserId } from '../../utils'

const NavigationButtons = (props: any) => {
  const {
    questions,
    setQuestions,
    selectedQuestion,
    previousQuestion,
    setPreviousQuestion,
    setNextQuestion,
    currentQuestion,
    answer,
  } = props
  const { id, response } = selectedQuestion
  const { portraitId } = usePhotoGallery()
  const history = useHistory()

  return (
    <div>
      <button
        onClick={() => {
          setNextQuestion(previousQuestion.length ? previousQuestion.pop() : 1)
        }}
      >
        PREVIOUS
      </button>
      <button
        onClick={() => {
          const next =
            response &&
            (selectedQuestion.response.next ||
              selectedQuestion.response.next === null)
              ? selectedQuestion.response.next
              : currentQuestion

          setPreviousQuestion([...previousQuestion, id])
          const questionsResponse: any = questions.map((q: any) => {
            if (q.id === id) {
              return {
                ...q,
                answer,
              }
            }
            return q
          })

          setQuestions(questionsResponse)

          if (next === null) {
            const body = {
              response: questionsResponse,
              author_id: getUserId(),
              portrait_id: portraitId,
            }
            axios
              .post(
                `${process.env.REACT_APP_API_ENDPOINT}/api/response`,
                body,
                {
                  headers: { Authorization: `Bearer ${getToken()}` },
                },
              )
              .then(() => {
                history.push('face')
              })
            return
          }

          setNextQuestion(next)
        }}
      >
        NEXT
      </button>
    </div>
  )
}

export default NavigationButtons
