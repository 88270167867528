import { useEffect, useState } from 'react'
import axios from 'axios'

export default function usePortraitList(pageNumber: any, author: any = null) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [portraits, setPortraits] = useState<Array<object>>([])
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    setLoading(true)
    setError(false)
    let cancel: any = () => {}
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/portraits`,
      params: { page: pageNumber, author: author },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then(({ data }: any) => {
        setPortraits((prev) => {
          return [...prev, ...data.data]
        })
        setHasMore(pageNumber < data.last_page)
        setLoading(false)
      })
      .catch((e) => {
        if (axios.isCancel(e)) return
        localStorage.removeItem('token')
        window.location.reload()
      })
    return () => cancel()
  }, [pageNumber, author])

  return {
    loading,
    error,
    portraits,
    hasMore,
  }
}
