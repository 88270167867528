import React from 'react'
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react'

import Questions from './Questions'
import Menu from '../components/Menu'

const PhotoResult = (props: any) => {
  return (
    <IonPage>
      <Menu contentId='resultContent' {...props} />
      <IonContent id='resultContent'>
        <IonGrid className='cameraGrid'>
          <IonRow className='cameraRow'>
            <IonCol className='cameraCol'>
              <Questions {...props} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default PhotoResult
