import { createContext, Dispatch, SetStateAction } from 'react'

export interface Photo {
  bearer: string
  setBearerToken: Dispatch<SetStateAction<string>>
}

export const UserContext = createContext<Photo>({
  bearer: '',
  setBearerToken: () => {},
})
