import React from 'react'
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButton,
  IonButtons,
  IonMenu,
  IonList,
  IonItem,
  IonMenuToggle,
  IonRouterOutlet,
} from '@ionic/react'
import { grid, menuOutline } from 'ionicons/icons'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'

import { useAuth } from '../hooks'

const Menu = (props: any) => {
  const { contentId } = props
  const { logout } = useAuth()
  const history = useHistory()
  return (
    <>
      <IonMenu side='start' contentId={contentId}>
        <IonContent>
          <IonList>
            <IonItem>
              <Link to='/face'>Home</Link>
            </IonItem>
            <IonItem>
              <Link to='/personal'>Personal</Link>
            </IonItem>
            <IonItem>
              <IonButton onClick={() => logout()}>Log Out</IonButton>
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonMenuToggle autoHide={false}>
              <IonButton>
                <IonIcon slot='icon-only' icon={menuOutline} />
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
          <IonButtons slot='primary'>
            <IonButton onClick={() => history.push('/face')}>
              <IonIcon slot='icon-only' icon={grid} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonRouterOutlet></IonRouterOutlet>
    </>
  )
}

export default Menu
